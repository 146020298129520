import {
  zip2addr
} from './zip2addr.js';

export default () => {
  $(document).on("input", "#postCode", () => {
    $('#postCode').zip2addr({
      pref: '#pref',
      addr: '#addr'
    });
  });
};
