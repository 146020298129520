const setVh = () => {
  // ウインドウサイズからVHの最小単位をカスタムプロパティに設定
  // 使用例：height: calc(var(--vh) * 100);
  let height = window.innerHeight;
  document.documentElement.style.setProperty("--vh", height / 100 + "px");
}

export default () => {
  const $html = jQuery('html'); // WP管理画面で$が使用できないため
  const $editorWrapper = jQuery('.editor-styles-wrapper');
  let $target;
  if ($editorWrapper.length) {
    $target = $editorWrapper;
  } else {
    $target = $html
  }
  $target.addClass('has-custom-property');
  setVh();
};

export {
  setVh
};