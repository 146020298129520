export default () => {

  const $inputMin = $('.js-min-date');
  const $inputMax = $('.js-max-date');
  const afterDays = 14; //14日後から予約可能
  const maxMonth = 2; //2ヶ月後まで予約可能

  const getAfterNdays = (n) => {
    const dt = new Date();
    dt.setDate(dt.getDate() + n);
    return formatDate(dt);
  };

  const getMaxdays = (n) => {
    const dt = new Date();
    dt.setMonth(dt.getMonth() + n);
    return formatDate(dt);
  };

  const formatDate = (dt) => {
    const y = dt.getFullYear();
    const m = ('00' + (dt.getMonth() + 1)).slice(-2);
    const d = ('00' + dt.getDate()).slice(-2);
    return (y + '-' + m + '-' + d);
  };

  if ($inputMin.length) {
    const value = getAfterNdays(afterDays);
    $inputMin.each((index, element) => {
      $(element).attr('min', value);
    });
  }

  if ($inputMax.length) {
    const value = getMaxdays(maxMonth);
    $inputMax.each((index, element) => {
      $(element).attr('max', value);
      $(element).attr('required', 'required'); //必須にすることで範囲外日付の入力をさせない
    });
  }

};