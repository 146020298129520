import slick from 'slick-carousel';
import anime from 'animejs/lib/anime.es.js';
import barba from '@barba/core';

export default () => {

  const $topKV = $('[data-top-kv]');

  if ($topKV.length) {

    const $slider = $('[data-top-kv-slides]');
    const $slide = $('[data-top-kv-slide]');

    if (!$slider.hasClass('slick-initialized')) {
      $('[data-js-preload]').imagesLoaded().always(() => {
          $slider.slick({
            fade: true,
            speed: 2000,
            autoplay: true,
            autoplaySpeed: 6000,
            accessibility: false,
            swipe: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            arrows: false,
            dots: false
          });

          //スライドエリアを表示
          $slide.css('opacity', 1);

          // 最初のスライドのアニメーション
          $slider.find('.slick-active').addClass('animation');

          // スライド切り替え時のアニメーションクラス付与・リセット
          $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {

            $slider.find('.slick-slide[data-slick-index= ' + nextSlide + ']').addClass('animation');

            window.setTimeout(() => {
              $slider.find('.slick-slide[data-slick-index= ' + currentSlide + ']').removeClass('animation');
            }, 2000);
          });

        })
        .progress((instance, image) => {
          const result = image.isLoaded ? 'loaded' : 'broken';
          if (image.isLoaded) {
            console.log('image is ' + result + ' for ' + image.img.currentSrc);
          }
        });
    }
  }
};