/**
 * SASS
 */
import '../sass/frontend.scss';

/**
 * JavaScript
 */

/**
 * Add here your JavasScript code
 */

"use strict";

/* -----------------------------------
プラグイン読み込み
----------------------------------- */
import imagesLoaded from 'imagesloaded';

// provide jQuery argument
imagesLoaded.makeJQueryPlugin($);

/* -----------------------------------
変数
----------------------------------- */
window.MCP = {};
MCP.breakPoint = 768;
MCP.viewMode = "";
MCP.resizeTimer = null;
MCP.currentWidth = window.innerWidth;

/* -----------------------------------
モジュール読み込み
----------------------------------- */
import barbaCustom from './lib/barbaCustom.js';
import viewModeCheck from './lib/viewModeCheck.js';
import {default as setCustomProperty, setVh} from './lib/setCustomProperty.js';
import switchImageSrc from './lib/switchImageSrc.js';
import menu from './lib/menu.js';
import loader from './lib/loader.js';
import formPostCode from './lib/formPostCode.js';
import formMinDate from './lib/formMinDate.js';
import thumbnailGallery from './lib/thumbnailGallery.js';
import photoGallery from './lib/photoGallery.js';

/* -----------------------------------
即実行関数
----------------------------------- */
setCustomProperty();

/* -----------------------------------
DOM構築後の実行関数
----------------------------------- */
$(function () {
  viewModeCheck();
  switchImageSrc();
  menu();
  loader();
  formPostCode();
  formMinDate();
  thumbnailGallery();
  photoGallery();
  barbaCustom();
});

/* -----------------------------------
リサイズ時の実行関数
----------------------------------- */
$(window).on("resize", () => {
  clearTimeout(MCP.resizeTimer);
  MCP.resizeTimer = setTimeout(() => {
    if (MCP.currentWidth !== window.innerWidth) {
      // ウインドウ横幅が変わったのでリサイズと見なす
      MCP.currentWidth = window.innerWidth;
      viewModeCheck();
      switchImageSrc();
      setVh();
    }
  }, 100);

});
