export default () => {

  const windowWidth = parseInt($(window).width());
  if (windowWidth >= MCP.breakPoint) {
    MCP.viewMode = "pc";
  } else {
    MCP.viewMode = "sp";
  }

  //console.log(MCP.viewMode);

};
