export default () => {

  const sticky = () => {
    const $menu = $('[data-sticky-menu]');
    if ($menu.length) {

      const setSticky = () => {
        const menuHeight = $menu.outerHeight() + 40;
        const windowHeight = $(window).height();
        if (menuHeight < windowHeight) {
          $menu.addClass('-sticky');
        } else {
          $menu.removeClass('-sticky');
        }
      }

      setSticky();

      let resizeTimer = null;
      $(window).on("resize", () => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
          setSticky();
        }, 200);
      });

    }
  }

  const autoIndex = () => {
    const $sideNav = $('[data-side-nav-auto-index]');
    if($sideNav) {
      const $skipIndex = $('[data-skip-index]');
      // $sideNavの中身へ$skipIndexを複製
      $sideNav.append($skipIndex.clone());

    }
  }

  sticky();
  autoIndex();


};