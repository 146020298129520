import {
  menuClose
} from './menu.js';
const $body = $('body');
const $quickMenu = $('.js-quick-menu');
const $quickMenuButton = $('.js-quick-menu-button')
const $quickMenuBg = $('.js-quick-menu-bg')
const $quickMenuContent = $('.js-quick-menu-content')
const $quickMenuList = $('.js-quick-menu-list')

const quickMenuClose = () => {
  $quickMenu.addClass('-closed');
  $body.removeClass('-no-scroll');
}

const quickMenuSetHeight = () => {
  const listHeight = $quickMenuList.outerHeight();
  $quickMenuContent.height(listHeight);
}

export default () => {

  quickMenuSetHeight();

  $($quickMenuButton).add($quickMenuBg).on('click', (event) => {
    if ($quickMenu.hasClass('-closed')) {
      $quickMenu.removeClass('-closed');
      menuClose();
      $body.addClass('-no-scroll');
    } else {
      quickMenuClose();
    }
  })
};

export {
  quickMenuClose,
  quickMenuSetHeight
};