const $triggerOpenMenu = $('[data-sp-menu-button]');
const $header = $('[data-header]');
const $headerNav = $('[data-header-nav]');
const $body = $('body');
const $menu = $('[data-header-sp-menu-index]');

const menuClose = () => {
  if ($triggerOpenMenu.hasClass("-opened")) {
    $menu.addClass('is-closing');
  }
  $body.removeClass('-no-scroll');
  $triggerOpenMenu.removeClass('-opened');
  $menu.removeClass('-opened');
  $header.removeClass('-opened');
  setTimeout(() => {
    $menu.removeClass('is-closing');
  }, 300);
}

const menuOpen = () => {
  $body.addClass('-no-scroll');
  $triggerOpenMenu.addClass('-opened');
  $menu.addClass('-opened');
  $header.addClass('-opened');
}

const menuCurrent = () => {
  $headerNav.find('a').removeClass('-current');
  const dir = location.pathname.split("/")[1];
  if (dir) {
    $headerNav.find('[href*="/' + dir + '/"]').addClass('-current');
  }
}

export default () => {

  const spMenu = () => {
    $triggerOpenMenu.on('click', event => {
      const $this = $(event.currentTarget);
      if (!$this.hasClass("-opened")) {
        menuOpen();
      } else {
        menuClose();
      }
    });
  }

  const fixedMenu = () => {

    const $body = $("body");
    const $header = $(".js-header");
    const fixedClass = "is-scroll-under";

    let startPos = 0;
    let winScrollTop = 0;

    const toggleFixedClass = () => {
      winScrollTop = $(window).scrollTop();

      if (winScrollTop >= startPos && winScrollTop > 100) {
        $body.addClass(fixedClass);
      } else {
        $body.removeClass(fixedClass);
      }
      startPos = winScrollTop;
      if (startPos <= 100) {
        $body.removeClass(fixedClass);
        $header.removeClass('has-background')
      } else {
        $header.addClass('has-background')
      }
    }

    toggleFixedClass();

    $(window).on("scroll", (event) => {
      toggleFixedClass();
    });
  }

  const dropDownMenu = () => {

    const $menu = $('[data-header-nav-dropdown]');
    const $menuButton = $('[data-header-nav-dropdown-button]');

    $menu.find('li').on('mouseenter', event => {
      if (MCP.viewMode === 'pc') {
        const $this = $(event.currentTarget);
        $this.find('[data-header-nav-dropdown-menu]').addClass('-show');
      }
    });

    $menu.find('li').on('mouseleave', event => {
      if (MCP.viewMode === 'pc') {
        const $this = $(event.currentTarget);
        $this.find('[data-header-nav-dropdown-menu]').removeClass('-show');
      }
    });

    $menuButton.on('click', event => {
      if (MCP.viewMode === 'sp') {
        const $this = $(event.currentTarget);
        $this.toggleClass('-show');
        const $thisMenu = $this.siblings('[data-header-nav-dropdown-menu]');
        $thisMenu.slideToggle();
      }
    })

  }

  spMenu();
  fixedMenu();
  dropDownMenu();
};

export {
  menuClose,
  menuOpen,
  menuCurrent
};