import slick from 'slick-carousel';
import anime from 'animejs/lib/anime.es.js';
import barba from '@barba/core';

export default () => {

  const $topKV = $('.js-top-kv');

  if ($topKV.length) {

    const $slider = $('.js-top-kv-slides');

    if (!$slider.hasClass('slick-initialized')) {
      $('.js-preload').imagesLoaded().always(() => {
          $slider.slick({
            fade: true,
            speed: 2000,
            autoplay: true,
            autoplaySpeed: 6000,
            accessibility: false,
            swipe: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            arrows: false,
            dots: true
          });

          //slick-active の初期アニメーションのためのクラスを一旦外す
          const $dots = $slider.find('.slick-dots');
          $dots.find('li').removeClass('slick-active').addClass('transition');
          window.setTimeout(() => {
            $dots.find('li').eq(0).addClass("slick-active");
          }, 10);

          //スライドエリアを表示
          $topKV.find('.p-top-kv__inner').css('opacity', 1);

          // タイポグラフィのアニメーション
          const tl = anime.timeline({
            easing: "easeOutQuad",
            duration: 1000,
          });
          const str1 = $topKV.find('.text1 path').length;
          const str2 = $topKV.find('.text2 path').length;
          const str3 = $topKV.find('.text3 path').length;
          tl.add({
            targets: '#top-kv-ja .text1 path',
            opacity: [0, 1],
            translateY: ['10%', '0px'],
            delay: anime.stagger(700 / str1)
          }, '1000').add({
            targets: '#top-kv-ja .text2 path',
            opacity: [0, 1],
            translateY: ['10%', '0px'],
            delay: anime.stagger(700 / str2)
          }, '2400').add({
            targets: '#top-kv-ja .text3 path',
            opacity: [0, 1],
            translateY: ['10%', '0px'],
            delay: anime.stagger(700 / str3)
          }, '3800');

          // 最初のスライドのアニメーション
          $slider.find('.slick-active').addClass('animation');

          // スライド切り替え時のアニメーションクラス付与・リセット
          $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {

            $slider.find('.slick-slide[data-slick-index= ' + nextSlide + ']').addClass('animation');

            window.setTimeout(() => {
              $slider.find('.slick-slide[data-slick-index= ' + currentSlide + ']').removeClass('animation');
            }, 2000);
          });

        })
        .progress((instance, image) => {
          const result = image.isLoaded ? 'loaded' : 'broken';
          if (image.isLoaded) {
            //console.log('image is ' + result + ' for ' + image.img.src);
          }
        });
    }
  }
};