export default () => {

  const $img = $('img[data-src]');
  $img.each((index, element) => {
    //console.log(MCP.viewMode)
    let src;
    if(MCP.viewMode === "pc") {
      src = $(element).data('src-pc');
    } else {
      src = $(element).data('src');
    }
    $(element).attr('src', src);
  });

}
