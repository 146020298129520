import slick from 'slick-carousel';
import barba from '@barba/core';

export default () => {

  const $gallery = $('.js-thumbnail-gallery');

  if ($gallery.length) {

    $gallery.each((index, element) => {
      const $thumb = $(element).find('.js-thumbnail-gallery__thumb');
      const $slide = $(element).find('.js-thumbnail-gallery__large');

      $slide.slick({
        dots: false,
        arrows: false,
        autoplay: false,
        centerMode: true,
        draggable: false,
        infinite: true,
        fade: true,
        speed: 200,
        responsive: [
          {
            breakpoint: 768,
            settings: {
            }
          },
        ]
      });

      /* --------------------------------------------------------
      スライドした際にその番号のリストにクラスを付与する
      -------------------------------------------------------- */
      $thumb.find('li').eq(0).addClass('is-current'); // 最初のリストへクラスを付与
      $slide.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        //console.log('slick is ' + nextSlide);
        $thumb.find('li').removeClass('is-current');
        $thumb.find('li').eq(nextSlide).addClass('is-current')
      });

      /* --------------------------------------------------------
      リストをクリックした際に、同じ順番のスライドへ移動する
      -------------------------------------------------------- */
      $thumb.find('li').on('click', (event) => {
        const $this = $(event.currentTarget);
        const target = $thumb.find('li').index($this);
        $thumb.find('li').removeClass('is-current');
        $slide.slick('slickGoTo', target);
        $this.addClass('is-current');
      });
    })


  }

};