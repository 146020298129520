export default () => {
  
  const $tab = $('.js-tab');

  $tab.each((index, element) => {
    $(element).children('.js-tab__content').children().not(':first').hide();
    $(element).children('.js-tab__nav').find('button').on('click', event => {
      const $this = $(event.currentTarget);
      const number = $(element).find('button').index($this);
      $(element).children('.js-tab__nav').find('button').removeClass('-current');
      $this.addClass('-current');
      $(element).children('.js-tab__content').children().hide();
      $(element).children('.js-tab__content').children(':eq(' + number + ')').fadeIn(400);
    });
  })

};