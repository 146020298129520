export default () => {

  /* divタグをマウスカーソルに追従させる */
  //マウスストーカー用のdivを取得
  const stalker = document.getElementById('loading-stalker');

  //上記のdivタグをマウスに追従させる処理
  document.addEventListener('mousemove', function (e) {
    const x = e.clientX + 20;
    const y = e.clientY + 20;
    stalker.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
  });

};