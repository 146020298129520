import slick from 'slick-carousel';
import barba from '@barba/core';

export default () => {

  const $slides = $('[data-pickup-slides]');

  if ($slides.length) {

    $slides.each((index, element) => {

      $(element).slick({
        dots: true,
        arrows: true,
        //autoplay: true,
        autoplaySpeed: 4000,
        variableWidth: true,
        centerMode: false,
        draggable: true,
        infinite: true,
        prevArrow: '<button type="button" class="p-top-pickup__button is-prev"></button>',
        nextArrow: '<button type="button" class="p-top-pickup__button"></button>',
        responsive: [{
          breakpoint: 768,
          settings: {
            centerMode: true,
          }
        }, ]
      });
    })
  }
};