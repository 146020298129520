export default () => {
  
  const $accordion = $('.js-accordion');

  $accordion.each((index, element) => {
    const $content = $(element).children('.js-accordion__content');
    const $button = $(element).children('.js-accordion__button');
    $button.on('click', event => {
      $content.slideToggle();
      $(element).toggleClass('-opened');
    });
  })

};