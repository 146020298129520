import slick from 'slick-carousel';
import barba from '@barba/core';

export default () => {

  const $gallery = $('.js-photo-gallery');

  if ($gallery.length) {

    $gallery.each((index, element) => {

      // 現在のスライド番号と合計スライド数を表示
      $(element).on('init reInit beforeChange', (event, slick, currentSlide, nextSlide) =>{
        const i = (nextSlide ? nextSlide : 0) + 1;
        $(element).siblings('.js-photo-count').find('.-current-count').text(i);
        $(element).siblings('.js-photo-count').find('.-all-count').text(slick.slideCount);
      });

      $(element).slick({
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 4000,
        centerMode: true,
        draggable: false,
        infinite: true,
        fade: true,
        speed: 1000,
        prevArrow: '<button type="button" class="slick-prev">PREV</button>',
        nextArrow: '<button type="button" class="slick-next">NEXT</button>',
        responsive: [
          {
            breakpoint: 768,
            settings: {
            }
          },
        ]
      });

      
    })


  }

};