import barba from '@barba/core';
import anime from 'animejs/lib/anime.es.js';
import switchImageSrc from './switchImageSrc.js';
import topAnimation from './topAnimation.js';
import topPickup from './topPickup.js';
import japaneseParser from './japaneseParser.js';
import sideMenuSticky from './sideMenuSticky.js';
import tab from './tab.js';
import modal from './modal.js';
import accordion from './accordion.js';
import thumbnailGallery from './thumbnailGallery.js';
import photoGallery from './photoGallery.js';
import {
  menuClose,
  menuCurrent
} from './menu.js';
import {
  quickMenuClose
} from './quickMenu.js';

// ヘッダー高さ
let headerHeight = 145;

export default () => {

  // 遷移後headタグの置き換え
  const replaceHead = (data) => {
    const head = document.head;
    const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
    const newPageHead = document.createElement('head');
    newPageHead.innerHTML = newPageRawHead;

    const removeHeadTags = [
      "meta[name='keywords']", "meta[name='description']", "meta[property^='og']", "meta[name^='twitter']", "meta[itemprop]", "link[itemprop]", "link[rel='prev']", "link[rel='next']", "link[rel='canonical']"
    ].join(',');

    const headTags = head.querySelectorAll(removeHeadTags)

    for (let i = 0; i < headTags.length; i++) {
      head.removeChild(headTags[i]);
    }

    const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)

    for (let i = 0; i < newHeadTags.length; i++) {
      head.appendChild(newHeadTags[i]);
    }

  }

  // 遷移後bodyクラスの置き換え
  const replaceBodyClass = (data) => {
    const nextHtml = data.next.html;
    const response = nextHtml.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', nextHtml)
    const bodyClasses = $(response).filter('notbody').attr('class')
    if (bodyClasses === undefined) {
      $('body').attr('class', '');
    } else {
      $('body').attr('class', bodyClasses);
    }
  }

  // スムーススクロール
  const smoothScroll = anchor => {
    const rect = anchor.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let offset;
    if (MCP.viewMode !== 'pc') {
      // SPの場合
      offset = 20;
    } else if (rect.top < 0 && MCP.viewMode === 'pc') {
      // PCで上方向スクロールの場合
      offset = headerHeight;
    } else {
      // PCで下方向スクロールの場合
      offset = 80;
    }
    const top = rect.top + scrollTop - offset;
    window.scrollTo({
      top: top,
      behavior: "smooth"
    });
  }

  /// ページ内スクロール判定
  const afterScroll = (data) => {
    // URLに「#」が存在するか
    if (location.hash) {
      const anchor = data.next.container.querySelector(location.hash);
      if (anchor) {
        smoothScroll(anchor);
      } else {
        // アンカー先が存在しなければページトップに
        window.scrollTo(0, 0);
      }
    }
  }

  // 同一URLの遷移を無効化
  const eventDelete = e => {
    const href = e.currentTarget.href;
    const target = e.currentTarget.target;
    const rel = e.currentTarget.rel;
    if (href.replace(/#.*$/, "") === window.location.href.replace(/#.*$/, "")) {
      e.preventDefault()
      e.stopPropagation()
      //アンカーだった場合その位置へスクロール、そうでなければ先頭へスクロール
      if (href.indexOf('#') > -1 && href.slice(-1) !== '#') {
        const anchor = document.querySelector("#" + href.substr(href.indexOf('#') + 1));
        if (anchor) {
          menuClose();
          quickMenuClose();
          smoothScroll(anchor);
        }
      } else if (href.slice(-1) === '#') {
        //hrefの値が#のみだった場合
        return
      } else {
        menuClose();
        quickMenuClose();
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
      return
    } else if(target !== '_blank' && rel !== 'external' && !/.pdf/.test(href) && !/tel:/.test(href) && !/\/entry\//.test(href) && !/\/reservation\//.test(href)) {
      $('#loading-stalker').addClass('-visible');
    }
  }

  // アンカーの取得とイベントの設定
  const linkSetting = () => {
    const links = [...document.querySelectorAll('a[href]')]
    links.forEach(link => {
      link.addEventListener('click', e => {
        eventDelete(e);
      }, false)
    })
  }

  barba.init({
    timeout: 10000,
    transitions: [{
      name: 'page-transition',
      sync: true,
      leave(data) {
        const done = this.async();
        data.current.container.style.top = -(window.scrollY) + "px";
        data.current.container.classList.add("js-barba__leave");

        window.scrollTo(0, 0);

        anime({
          targets: data.current.container,
          opacity: [1, 0],
          easing: "easeOutQuad",
          duration: 500,
          complete: function () {
            done();
            menuClose();
            quickMenuClose();
          }
        })
      },
      enter(data) {
        switchImageSrc();
        japaneseParser();
        const done = this.async();
        const $target = $(data.next.container);
        $target.css('opacity', 0);
        $target.find('.js-preload').imagesLoaded().always(() => {
            $('#loading-stalker').removeClass('-visible');
            anime({
              targets: data.next.container,
              opacity: [0, 1],
              translateY: ['-20px', 0],
              easing: "easeOutQuad",
              duration: 500,
              delay: 500,
              complete: function () {
                done();
                $target.removeAttr('style'); //子要素のposition:fixedが効かなくなるのを回避
              }
            });
          })
          .progress((instance, image) => {
            const result = image.isLoaded ? 'loaded' : 'broken';
            if (image.isLoaded) {
              //console.log('image is ' + result + ' for ' + image.img.src);
            }
          });
      }
    }],

    prevent: (e) => {
      // WP管理バーなど、barbaのリンクとして実行させたくない場合の処理
      // ab-item はWP管理バーのクラス
      if (e.el.classList.contains('ab-item') || e.el.classList.contains('is-no-barba') || /.pdf/.test(e.el.href) || /.jpg/.test(e.el.href) || /.png/.test(e.el.href) || /\/entry\//.test(e.el.href) || /\/reservation\//.test(e.el.href) || /\/reservation2\//.test(e.el.href) || /\/reservation3\//.test(e.el.href)) {
        return true;
      }
    }

  });

  barba.hooks.beforeEnter((data) => {
    replaceHead(data); //head内を書き換え
    replaceBodyClass(data); //bodyクラスを書き換え
  });

  barba.hooks.afterEnter((data) => {
    // ページ遷移時、もしくはリロードの際に実行する処理
    //$(window).off('scroll'); // スクロールイベントの重複実行を防ぐ
    afterScroll(data);
    linkSetting();
    topAnimation();
    topPickup();
    //topModalNotice();
    tab();
    modal();
    accordion();
    menuCurrent();
  });

  barba.hooks.after((data) => {
    // ページ遷移後に実行する処理
    thumbnailGallery();
    photoGallery();
    sideMenuSticky();
    linkSetting();
  });

};