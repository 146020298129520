export default () => {

  const $modalButton = $('.js-modal-button');

  if ($modalButton.length) {
    const $modalBoxes = $('.js-modal-boxes');
    const $modalClose = $('.js-modal-close');

    //クリックしたボタンと同じIDのモーダルを開く
    $modalButton.on('click', event => {
      const $this = $(event.currentTarget);
      const target = $this.data('modal');
      $modalBoxes.find('.js-modal-box').hide();
      $modalBoxes.find('[data-modal=' + target + ']').show();
      $modalBoxes.fadeIn(200).css('display', 'flex');
      $('body').addClass('-no-scroll');
    });

    //モーダルボックス外のクリックでモーダルを閉じる
    $modalBoxes.on('click', event => {
      if (!$(event.target).closest('.js-modal-box').length) {
        $modalBoxes.fadeOut(200);
        $('body').removeClass('-no-scroll');
      }
    });
  };
};